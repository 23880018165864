import { render, staticRenderFns } from "./exchange.vue?vue&type=template&id=f4aeed28&scoped=true"
import script from "./exchange.vue?vue&type=script&lang=ts"
export * from "./exchange.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4aeed28",
  null
  
)

export default component.exports