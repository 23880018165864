
/**
 * @name: 数据统计-礼品卡兑换记录
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 数据统计-礼品卡兑换记录
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import {giftCartExchangeExportApi, queryGiftCartExchangeByPageApi} from "@/apis/statistics/exchange"
import { IStatisticsExchangeParam, IStatisticsExchange } from "@/apis/statistics/exchange/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy, exportFile} from "@/utils/common";

@Component({})
export default class statisticsExchange extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IStatisticsExchange[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IStatisticsExchangeParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "卡号",
        prop: "cardNumber",
        search: true,
        align: "center",
        width: 150,
        slot: true
      },
      {
        label: "生成批次ID",
        prop: "giftCouponId",
        search: true,
        hide: true
      },
      // {
      //   label: "礼品卡类别",
      //   prop: "giftCategoryId",
      //   search: true,
      //   hide: true,
      //   type: "select",
      //   "dicUrl": "/api/card/gift/category/getList",
      //   "dicFormatter": (res: any) => ({list: res.data, label: 'giftCategoryName', value: 'giftCategoryId'}),
      // },
      {
        label: "礼品卡类别",
        prop: "giftCategoryName",
        width: 150,
        align: "center",
        search: true
      },
      {
        label: "面值(元)",
        prop: "denomination",
        align: "center"
      },
      {
        label: "绑定账号",
        prop: "userPhone",
        align: "center",
        search: true
      },
      {
        label: "状态",
        prop: "status",
        align: "center",
        type: "select",
        search: true,
        hide: true,
        dicData: [
          {
            label: "已使用",
            value: 3
          },
          {
            label: "已用完",
            value: 4
          },
          {
            label: "已过期",
            value: 5
          }
        ]
      },
      {
        label: "订单编号",
        prop: "orderSn",
        align: "center",
        search: true
      },
      {
        label: "激活时间",
        prop: "activateTime",
        search: true,
        hide: true,
        type: "daterange"
      },
      {
        label: "消费时间",
        prop: "expenseTime",
        align: "center",
        width: 150,
        search: true,
        type: "daterange"
      },
      {
        label: "使用完成时间",
        prop: "useFinishTime",
        search: true,
        hide: true,
        type: "daterange"
      },
      {
        label: "消费金额",
        prop: "expensePrice",
        slot: true,
        width: 100,
        align: "center"
      },
    ]
  }

  expensePriceTotal = 0

  getList () {
    this.tableLoading = true

    const query: IStatisticsExchangeParam = deepCopy(this.queryParam)
    if (query.activateTime && query.activateTime.length) {
      query.activateTimeStart = query.activateTime[0]
      query.activateTimeEnd = query.activateTime[1]
    } else {
      query.activateTimeStart = ''
      query.activateTimeEnd = ''
    }
    delete query.activateTime

    if (query.expenseTime && query.expenseTime.length) {
      query.expenseTimeStart = query.expenseTime[0]
      query.expenseTimeEnd = query.expenseTime[1]
    } else {
      query.expenseTimeStart = ''
      query.expenseTimeEnd = ''
    }
    delete query.expenseTime

    if (query.useFinishTime && query.useFinishTime.length) {
      query.useFinishTimeStart = query.useFinishTime[0]
      query.useFinishTimeEnd = query.useFinishTime[1]
    } else {
      query.useFinishTimeStart = ''
      query.useFinishTimeEnd = ''
    }
    delete query.useFinishTime

    queryGiftCartExchangeByPageApi(query).then(e => {

      this.tableData = e.commonPage.list
      this.tableTotal = e.commonPage.total

      this.expensePriceTotal = e.expensePriceTotal || 0

      this.tableLoading = false
    })
  }


  exportExcel () {
    const query: IStatisticsExchangeParam = deepCopy(this.queryParam)
    if (query.activateTime && query.activateTime.length) {
      query.activateTimeStart = query.activateTime[0]
      query.activateTimeEnd = query.activateTime[1]
    } else {
      query.activateTimeStart = ''
      query.activateTimeEnd = ''
    }
    delete query.activateTime

    if (query.expenseTime && query.expenseTime.length) {
      query.expenseTimeStart = query.expenseTime[0]
      query.expenseTimeEnd = query.expenseTime[1]
    } else {
      query.expenseTimeStart = ''
      query.expenseTimeEnd = ''
    }
    delete query.expenseTime

    if (query.useFinishTime && query.useFinishTime.length) {
      query.useFinishTimeStart = query.useFinishTime[0]
      query.useFinishTimeEnd = query.useFinishTime[1]
    } else {
      query.useFinishTimeStart = ''
      query.useFinishTimeEnd = ''
    }
    delete query.useFinishTime
    giftCartExchangeExportApi(query).then(e => {
      exportFile(e, "礼品卡兑换记录.xlsx")
    })
  }

  created () {
    this.getList()
  }
}
